import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { TbBusinessplan } from 'react-icons/tb';
import { CgLogOff } from 'react-icons/cg';
import { IoMailOutline } from 'react-icons/io5';
import { MdOutlineNewLabel } from 'react-icons/md';
import { BsPlayCircle } from 'react-icons/bs';

import { useSystemContext } from '../../contexts';

import './style.scss';
import PATHS from '../../routes/paths';

export function SideNav() {
  const location = useLocation();
  const { user, showSideNav, onLogout } = useSystemContext();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  if (!showSideNav || !user) return null;

  return (
    <aside>
      <nav>
        <ul>
          <li className="nav-item">
            <Link
              to={PATHS.HOME()}
              className={`nav-link ${location.pathname === PATHS.HOME() || location.pathname === PATHS.MY_PLANS() ? 'active' : ''}`}
              data-tip="Meus planejamentos"
              data-place="right"
            >
              {/* <AiFillHome /> */}
              <TbBusinessplan />
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/meus-planejamentos/criar"
              className={`nav-link ${location.pathname === '/meus-planejamentos/criar' ? 'active' : ''}`}
              data-tip="Criar planejamento"
              data-place="right"
            >
              <MdOutlineNewLabel />
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={PATHS.TUTORIAL()}
              className={`nav-link ${location.pathname === PATHS.TUTORIAL() ? 'active' : ''}`}
              data-tip="Tutorial"
              data-place="right"
            >
              <BsPlayCircle />
            </Link>
          </li>
          <li className="nav-item">
            <a
              href="https://agoravoceaprendeinvestir.com/contacto/"
              target="_blank"
              rel="noopener noreferrer"
              data-tip="Contato"
              data-place="right"
              className={`nav-link`}
            >
              <IoMailOutline />
            </a>
          </li>
          <li className="nav-item">
            <button className="nav-link" type="button" data-tip="Sair" data-place="right" onClick={() => onLogout(true)}>
              <CgLogOff />
            </button>
          </li>
        </ul>
      </nav>
    </aside>
  );
}
