const PATHS: any = {
  HOME: () => '/',
  AUTH: () => '/entrar',
  TUTORIAL: () => '/tutorial',
  MY_PLANS: () => '/meus-planejamentos',
  PLAN: () => '/meus-planejamentos/:action/:planID?',
  CONTACT: () => '/contato',
  PROFILE: () => '/meu-perfil',
};

export default PATHS;
