import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import 'moment/locale/pt-br';

import SystemContextProvider from './contexts/use-system-context';
import MyRoutes from './routes';

function App() {
  moment.locale('pt-br');

  return (
    <BrowserRouter>
      <SystemContextProvider>
        <MyRoutes />
      </SystemContextProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ReactTooltip type="info" effect="solid" className="tooltip" />
    </BrowserRouter>
  );
}

export default App;
