import { useState, useEffect } from 'react';
import { useSystemContext } from '../../contexts';

import logo from '../../assets/images/logo.png';

import './style.scss';

export function Header() {
  const systemContext = useSystemContext();
  const { user, isAuthenticated } = systemContext;

  useEffect(() => {}, []);

  const getInitialsName = () => {
    if (typeof user?.nome === 'string') {
      const names = user.nome.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }

      return initials;
    } else {
      return null;
    }
  };

  return (
    <header>
      <div className="logo">
        <h1>
          <img src={logo} alt="Agora VAI" />
        </h1>
        <h2>
          Fórmula <span>Mágica</span>
        </h2>
      </div>

      {!!isAuthenticated() && (
        <div className="user">
          <p>{user?.nome}</p>
          {!!getInitialsName() && <span>{getInitialsName()}</span>}
        </div>
      )}
    </header>
  );
}
