import Api from './Api';

const Auth = {
  register: async (nome: string, email: string) => {
    if (typeof nome !== 'string' || typeof email !== 'string') return null;

    return await Api.get(`/acesso/incluir?nome=${nome.trim()}&email=${email.trim()}`);
  },
  login: async (email: string, password: string) => {
    if (typeof email !== 'string' || typeof password !== 'string') return null;

    return await Api.get(`/acesso/entrar?email=${email.trim()}&senha=${password.trim()}`);
  },
  me: async (email: string) => {
    if (typeof email !== 'string') return null;

    return await Api.get(`/acesso/consultar?email=${email.trim()}`);
  },
  forgotPassword: async (email: string) => {
    if (typeof email !== 'string') return null;

    return await Api.get(`/acesso/esqueci?email=${email.trim()}`);
  },
  insertCode: async (code: string) => {
    if (typeof code !== 'string') return null;

    return await Api.get(`/acesso/recuperar?vcode=${code.trim()}`);
  },
  changePassword: async (password: string, password2: string) => {
    if (typeof password !== 'string' || typeof password2 !== 'string' || password !== password2) return null;

    return await Api.get(`/acesso/alterar?senha=${password}&confirma=${password2}`);
  },
  logout: async () => {
    return await Api.get(`/acesso/sair`);
  },
};

export default Auth;
