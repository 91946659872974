import { useState, useEffect } from 'react';
import { useSystemContext } from '../../contexts';

import './style.scss';

export function Footer() {
  const systemContext = useSystemContext();
  const { user } = systemContext;

  useEffect(() => {}, []);

  return <footer></footer>;
}
