import { useContext, createContext, useState, useEffect, useLayoutEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { USER_KEY } from '../constants';
import { asyncLocalStorage, decodeEntity, isJsonObject } from '../utils';
import { ChildrenProp, PlansInterface } from '../interfaces';
import Auth from '../service/Auth';
import Plan from '../service/Plan';
import Lot from '../service/Lot';
import PATHS from '../routes/paths';

export interface UserInterface {
  nome: string;
  email: string;
  telefone: string;
  verificado: boolean;
}

export interface OnLoginInterface {
  email: string;
  password: string;
}

export interface OnLoginReturn {
  type: string;
  message?: string;
}

export interface SystemContextInterface {
  plansLoading: boolean;
  fullLoading: boolean;
  showHeader: boolean;
  showFooter: boolean;
  showSideNav: boolean;
  sideNavOpened: boolean;
  user: UserInterface;
  customClass: string;
  width: number;
  height: number;
  authLoading: boolean;
  plans: any;
  isAuthenticated: () => boolean;
  setPlans: (value: PlansInterface[]) => void;
  setAuthLoading: (value: boolean) => void;
  setFullLoading: (value: boolean) => void;
  setShowHeader: (value: boolean) => void;
  setShowFooter: (value: boolean) => void;
  setShowSideNav: (value: boolean) => void;
  setSideNavOpened: (value: boolean) => void;
  onLogin: (credentials: OnLoginInterface) => Promise<OnLoginReturn>;
  onLogout: (value?: boolean) => void;
  setUser: (value: UserInterface) => void;
  setCustomClass: (value: string) => void;
  getUser: (value: string, loadPlans?: boolean) => void;
  updatePlans: () => Promise<any>;
}

const systemContext = createContext({} as SystemContextInterface);

export const useSystemContext = (): SystemContextInterface => {
  return useContext(systemContext);
};

function PropsSystemContextProvider(): SystemContextInterface {
  const location = useLocation();
  const navigate = useNavigate();

  const [fullLoading, setFullLoading] = useState<boolean>(false);
  const [plansLoading, setPlansLoading] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [showSideNav, setShowSideNav] = useState<boolean>(false);
  const [sideNavOpened, setSideNavOpened] = useState<boolean>(true);
  const [user, setUser] = useState<any>();
  const [plans, setPlans] = useState<PlansInterface[]>();
  const [profitabilityLoaded, setProfitabilityLoaded] = useState<boolean>(false);

  const [customClass, setCustomClass] = useState<string>('');
  const [width, height] = useWindowSize();

  const isAuthenticated = () => {
    const userStorage = localStorage.getItem(USER_KEY);
    const dataUser: UserInterface = userStorage ? JSON.parse(userStorage) : null;

    if (dataUser && dataUser.email) {
      return true;
    } else {
      return false;
    }
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);

      updateSize();

      return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
  }

  async function initialLoad() {
    const userStorage = await asyncLocalStorage.getItem(USER_KEY);
    const dataUser: UserInterface = userStorage ? JSON.parse(userStorage) : null;

    if (dataUser && dataUser.email) {
      setFullLoading(true);
      await getUser(dataUser.email);
      setFullLoading(false);
    } else {
      setFullLoading(false);
    }
  }

  function onLogin(credentials: OnLoginInterface): Promise<any> {
    setFullLoading(true);

    return new Promise((resolve, reject) => {
      Auth.login(credentials.email, credentials.password)
        .then(async resp => {
          if (isJsonObject(resp?.data) && !!resp?.headers['content-type'].match(/json/gi)) {
            const logged = await getUser(credentials.email, true);
            resolve(logged);
          } else {
            reject({ type: 'error', message: 'Credenciais inválidas. Por favor, tente novamente.' });
          }
        })
        .catch(error => {
          let message = 'Ocorreu um erro na solicitação. Por favor, tente novamente.';

          toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          if (
            isJsonObject(error.response?.data) &&
            !!error.response?.headers['content-type']?.match(/json/gi) &&
            error?.response?.data?.content
          ) {
            message = decodeEntity(error?.response?.data?.content);
          }

          reject({ type: 'error', message: message });
        })
        .finally(() => setFullLoading(false));
    });
  }

  const onLogout = useCallback(
    async (requiredByUser = false) => {
      await Auth.logout();
      setUser(undefined);
      localStorage.removeItem(USER_KEY);
      setFullLoading(false);

      let message = 'Você foi desconectado(a) do sistema. Por favor, faça um novo login.';

      if (requiredByUser) {
        message = 'Logout realizado com sucesso.';
      }

      toast(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return navigate(PATHS.AUTH());
    },
    [navigate],
  );

  const updatePlans = useCallback(async (fullLoading = false): Promise<PlansInterface[]> => {
    setPlansLoading(true);

    if (fullLoading) {
      setFullLoading(true);
    }

    try {
      let myPlans: PlansInterface[] = [];

      const plan = await Plan.list({ page: 0 });

      if (plan?.data?.content?.result?.length) {
        myPlans = plan?.data?.content?.result;
      }

      // if (!!plan?.data?.content?.pages && Number(plan?.data?.content?.pages) > 1) {
      //   const nextPlans: any = [];

      //   for (let page = 2; page < Number(plan?.data?.content?.pages) + 1; page++) {
      //     nextPlans.push(
      //       Plan.list({ page }).then(response => {
      //         const data = response?.data;

      //         if (data?.content?.result?.length) {
      //           data?.content?.result.forEach((result: any) => {
      //             myPlans.push(result);
      //           });
      //         }
      //       }),
      //     );
      //   }

      //   await Promise.all(nextPlans);
      // }

      if (myPlans.length) {
        const lots: any = [];
        const profitability: any = [];

        myPlans.forEach((_plan, indexPlan) => {
          lots.push(
            Lot.list({ page: 0, plano: _plan.codigo }).then(async response => {
              const _lots = response?.data;

              myPlans[indexPlan].lotes_lista = [];

              if (_lots?.content?.result?.length) {
                myPlans[indexPlan].lotes_lista = _lots?.content?.result;
              }

              // if (!!_lots?.data?.content?.pages && Number(_lots?.data?.content?.pages) > 1) {
              //   const nextLots: any = [];

              //   for (let page = 2; page < Number(_lots?.data?.content?.pages) + 1; page++) {
              //     nextLots.push(
              //       Lot.list({ page, plano: _plan.codigo }).then(response => {
              //         const data = response?.data;

              //         if (data?.content?.result?.length) {
              //           data?.content?.result.forEach((result: any) => {
              //             myPlans[indexPlan].lotes_lista?.push(result);
              //           });
              //         }
              //       }),
              //     );
              //   }

              //   await Promise.all(nextLots);
              // }
            }),
          );

          profitability.push(
            Plan.profitability({ page: 0, codigo: _plan.codigo }).then(async response => {
              const _response = response?.data;

              if (_response?.content && _response?.type === 'success') {
                myPlans[indexPlan].profitability = _response?.content;
              }
            }),
          );
        });

        await Promise.all(lots);
        await Promise.all(profitability);
      }

      // console.log('myPlans', myPlans);

      setPlans(myPlans);

      return myPlans;
    } catch (error) {
      setPlans([]);
      return [];
    } finally {
      setPlansLoading(false);

      if (fullLoading) {
        setFullLoading(false);
      }
    }
  }, []);

  const getUser = useCallback(
    async (email: string, loadPlans = false) => {
      try {
        if (typeof email !== 'string') {
          // await onLogout();
          throw new Error();
        }

        return await Auth.me(email)
          .then(async user => {
            const parseUser = { ...user?.data?.content, verificado: user?.data?.content.verificado === '1' };

            localStorage.setItem(USER_KEY, JSON.stringify(parseUser));

            if (loadPlans || !plans) {
              await updatePlans();
            }

            setUser(parseUser);

            return { type: 'success', message: '' };
          })
          .catch(error => {
            throw new Error();
          });
      } catch (error) {
        return onLogout();
      }
    },
    [onLogout, plans, updatePlans],
  );

  // const updateProfitability = useCallback(async () => {
  //   if (plans?.length && !profitabilityLoaded) {
  //     const profitability: any = [];
  //     const myPlans = [...plans];

  //     myPlans.forEach((_plan, indexPlan) => {
  //       profitability.push(
  //         Plan.profitability({ page: 0, codigo: _plan.codigo }).then(async response => {
  //           const _response = response?.data;

  //           if (_response?.content && _response?.type === 'success') {
  //             myPlans[indexPlan].profitability = _response?.content;
  //           }
  //         }),
  //       );
  //     });

  //     await Promise.all(profitability);

  //     setProfitabilityLoaded(true);

  //     console.log('myPlansProfitability', myPlans);

  //     setPlans(myPlans);
  //   }
  // }, [plans, profitabilityLoaded]);

  // useEffect(() => {
  //   if (plans?.length && !profitabilityLoaded) {
  //     updateProfitability();
  //   }
  // }, [plans, profitabilityLoaded, updateProfitability]);

  useEffect(() => {
    if (user) {
      setShowSideNav(true);
    } else {
      setShowSideNav(false);
    }
  }, [user]);

  useEffect(() => {
    // initialLoad();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    ReactTooltip.rebuild();

    const userStorage = localStorage.getItem(USER_KEY);
    const dataUser: UserInterface = userStorage ? JSON.parse(userStorage) : null;

    if (dataUser && dataUser.email) {
      getUser(dataUser.email);
    }
  }, [getUser, location]);

  return {
    plansLoading,
    fullLoading,
    showHeader,
    showFooter,
    showSideNav,
    sideNavOpened,
    user,
    customClass,
    width,
    height,
    authLoading,
    plans,
    isAuthenticated,
    setPlans,
    setAuthLoading,
    setFullLoading,
    setShowHeader,
    setShowFooter,
    setShowSideNav,
    setSideNavOpened,
    onLogin,
    onLogout,
    setUser,
    setCustomClass,
    getUser,
    updatePlans,
  };
}

const SystemContextProvider = (props: ChildrenProp) => {
  const _props: SystemContextInterface = PropsSystemContextProvider();
  return <systemContext.Provider value={_props}>{props.children}</systemContext.Provider>;
};

export default SystemContextProvider;
