import axios from 'axios';

const Api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API,
  responseType: 'json',
  timeout: 35000,
});

Api.interceptors.request.use(
  async config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default Api;
