import { FC, lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useSystemContext } from '../contexts';
import PATHS from './paths';

const HomePage = lazy(() => import('../pages/Home'));
const AuthPage = lazy(() => import('../pages/Auth'));
const TutorialPage = lazy(() => import('../pages/Tutorial'));
const MyPlansPage = lazy(() => import('../pages/MyPlans'));
const PlanPage = lazy(() => import('../pages/Plan'));
const ContactPage = lazy(() => import('../pages/Contact'));
const ProfilePage = lazy(() => import('../pages/Profile'));

const ProtectedRoute: FC<any> = ({ redirectPath = PATHS.AUTH(), children }) => {
  const systemContext = useSystemContext();
  const { isAuthenticated } = systemContext;

  if (!isAuthenticated()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default function RoutesLayout() {
  return (
    <>
      <Routes>
        <Route
          path={PATHS.AUTH()}
          element={
            <Suspense fallback={<div />}>
              <AuthPage />
            </Suspense>
          }
        />

        <Route element={<ProtectedRoute />}>
          <Route
            index
            element={
              <Suspense fallback={<div />}>
                <Navigate to={PATHS.MY_PLANS()} replace />
              </Suspense>
            }
          />
          <Route
            path={PATHS.HOME()}
            element={
              <Suspense fallback={<div />}>
                <Navigate to={PATHS.MY_PLANS()} replace />
              </Suspense>
            }
          />
          <Route
            path={PATHS.TUTORIAL()}
            element={
              <Suspense fallback={<div />}>
                <TutorialPage />
              </Suspense>
            }
          />
          {/* <Route
            path={PATHS.MY_PLANS()}
            element={
              <Suspense fallback={<div />}>
                <MyPlansPage />
              </Suspense>
            }
          > */}
          <Route path={PATHS.MY_PLANS()} element={<Outlet />}>
            <Route
              index
              element={
                <Suspense fallback={<div />}>
                  <MyPlansPage />
                </Suspense>
              }
            />
            <Route path=":action" element={<Outlet />}>
              <Route
                index
                element={
                  <Suspense fallback={<div />}>
                    <PlanPage />
                  </Suspense>
                }
              />
              <Route
                path=":planID"
                element={
                  <Suspense fallback={<div />}>
                    <PlanPage />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route
            path={PATHS.CONTACT()}
            element={
              <Suspense fallback={<div />}>
                <ContactPage />
              </Suspense>
            }
          />
          <Route
            path={PATHS.PROFILE()}
            element={
              <Suspense fallback={<div />}>
                <ProfilePage />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="*"
          element={
            <Suspense fallback={<div />}>
              <p>There's nothing here: 404!</p>
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}
