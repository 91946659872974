import { Footer, Header, SideNav, Splash } from '../../components';
import { useSystemContext } from '../../contexts';
import { ChildrenProp } from '../../interfaces';

export default function Layout(props: ChildrenProp) {
  const systemContext = useSystemContext();
  const { fullLoading, showFooter, showHeader, customClass, showSideNav } = systemContext;

  return (
    <>
      {!!fullLoading && <Splash />}
      <div
        className={`container ${showSideNav ? 'show-side-nav' : ''} ${
          typeof customClass === 'string' && customClass.length ? `${customClass}` : ''
        }`}
      >
        {showHeader && <Header />}

        <div className="content">
          <SideNav />
          <main>{props.children}</main>
        </div>

        {showFooter && <Footer />}

        {/* <Cookies /> */}
      </div>
    </>
  );
}
