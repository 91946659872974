import Api from './Api';
import { serializeObject } from '../utils';

const Plan = {
  include: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/plano/incluir?${serializeObject(obj)}`);
  },

  list: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/plano/listar?${serializeObject(obj)}`);
  },

  get: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/plano/consultar?${serializeObject(obj)}`);
  },

  edit: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/plano/alterar?${serializeObject(obj)}`);
  },

  nextActivities: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/plano/atividades?${serializeObject(obj)}`);
  },

  profitability: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/plano/rendimentos?${serializeObject(obj)}`);
  },

  exclude: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/plano/excluir?${serializeObject(obj)}`);
  },
};

export default Plan;
