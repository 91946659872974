import Api from './Api';
import { serializeObject } from '../utils';

const Lot = {
  include: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/lote/incluir?${serializeObject(obj)}`);
  },

  list: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/lote/listar?${serializeObject(obj)}`);
  },

  get: async (obj: any) => {
    if (typeof obj !== 'object') return null;

    return await Api.get(`/financeiro/lote/consultar?${serializeObject(obj)}`);
  },
};

export default Lot;
